<template>
  <document-control
    source="transit/getControl"
    pin-name="controls"
  >
    <template #action>
      <common-errors-action />
    </template>
  </document-control>
</template>
<script>
import DocumentControl from "@/components/shared/document-control.vue";
import CommonErrorsAction from "./common-errors-action.vue";

export default {
  components: {
    DocumentControl,
    CommonErrorsAction,
  },
};
</script>
